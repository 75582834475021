<template>
  <div class="chat-container">
    <!--サイドバー表示ボタン-->
    <button class="threebaricon" v-if="!showSideBar" @click="showSideBar = true">
      <img src="@/assets/ThreeBar.png" alt="ThreeBar.png" class="threebar-icon" />
    </button>

    <!--ChatBotタイトル-->
    <h2 class="title">営農支援AI(仮称) -SBC環境-</h2>
    <!--サインアウトボタン-->
    <button class="signoutbtn" @click="logout">サインアウト</button>

    <!--入力された文字列と応答を画面に出力-->
    <div class="message-container" ref="messageContainer">
      <!--「SideBar.vue」からのトリガーで発動される関数を定義-->
      <!--サイドバーコンポーネントを表示する条件に基づいて表示・非表示を切り替える-->
      <!--サイドバーの「閉じる」ボタンが押されたときには、closeSidebarメソッドが呼び出されてサイドバーが非表示になる-->
      <SideBar
        @session-selected="handleSesstionSelect"
        v-if="showSideBar"
        @close-sidebar="closeSidebar"
        @show-confirm="showConfirmDialog"
        @session-backup="getSessionId"
        @initialize-false="initializeFalse"
        @index-backup="indexBackup"
        ref="sidebar"
        :isInitialize="isInitialize"
        :conversation_id="conversation_id"
        :activeindex_backup="activeindex_backup"
        :userId="userId"
        :idToken="idToken"
        :last_login_time="last_login_time"
      />
      <!--チャット履歴削除画面の表示判定-->
      <ConfirmDialog
        v-if="isDialogVisible"
        @close="closeConfirmDialog"
        @delete-chat="deleteSession"
      />

      <!-- メッセージリストをループで表示 -->
      <div v-for="(message, index) in messages" :key="index">
        <!--userモードの時-->
        <!--ユーザーエリアにマウスを持っていくと、ゴミ箱ボタンを表示(ユーザーエリア以外にマウスを持っていくと、ゴミ箱ボタンを非表示にする)-->
        <div
          v-if="message.question"
          :class="['message', message.question.type]"
          @mouseenter="showButton(index)"
          @mouseleave="hideButton(index)"
        >
          <div class="messageicon-container">
            <!--userアイコンを表示-->

            <!--AIからの回答が返ってきている場合-->
            <!--ユーザーからの回答が返ってきている場合-->
            <img
              :src="getIcon(message.question.type)"
              class="message-icon"
              :alt="message.question.type + ' icon'"
            />
            <!--ゴミ箱ボタンを配置(ユーザー側のみ)-->
            <button
              class="trashicon"
              v-show="showTrashButton[index]"
              @click="deleteSomeConversationData(index)"
            >
              <img src="@/assets/TrashBox.png" alt="TrashBox.png" class="trash-icon" />
            </button>
          </div>

          <!--メッセージ表示部分-->
          <!--userモードの時-->
          <div class="message-text">{{ formatMessage(message.question.text) }}</div>
        </div>

        <!--botモードの時-->
        <!--ユーザーエリアにマウスを持っていくと、ゴミ箱ボタンを表示(ユーザーエリア以外にマウスを持っていくと、ゴミ箱ボタンを非表示にする)-->
        <div v-if="message.answer" :class="['message', message.answer.type]">
          <div class="messageicon-container">
            <!--botアイコンを表示-->
            <img
              :src="getIcon(message.answer.type)"
              class="message-icon"
              :alt="message.answer.type + ' icon'"
            />
          </div>

          <!--メッセージ表示部分-->

          <!--botのメッセージを表示-->
          <div v-if="loading && message == messages[messages.length - 1]" class="loader">
            <img src="@/assets/Loading.gif" alt="Loading..." />
          </div>
          <!--メッセージを表示-->
          <div v-else class="message-text">
            <div>{{ formatMessage(message.answer.text) }}</div>
            <!--RAGから返ってきた画像URLを1つずつ読み込み-->
            <div v-if="message.imageUrl">
              <div v-for="(url, index) in message.imageUrl" :key="index">
                <br />
                <img :src="url" alt="rag_image_photo" class="image-size" />
              </div>
            </div>

            <!--ToDo：グラフ対応再開時に、再度、グラフ描画関連のメントアウトを外すこと-->

            <!--グラフ情報が返ってきている会話セットのみ、グラフを描画-->
            <!-- <div v-if="message.options">
              <br />
              <apexChart
                :key="index"
                type="line"
                height="400"
                :options="message.options"
                :series="message.series"
              >
              </apexChart>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!--メッセージ入力フォーム-->
    <div class="generalformposition">
      <div class="input-container">
        <textarea
          placeholder="AIにメッセージを送信する"
          v-model="newMessage"
          @keydown="handleKeydown"
          @input="checkTextLength"
          id="textarea"
          required
        >
        </textarea>
        <!--メッセージ送信ボタン(飛行機アイコン仕様)-->
        <button class="sendbtn" @click="sendMessage">
          <img src="@/assets/PaperFly.png" alt="PaperFly.png" class="paperfly-icon" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmDialog from "./components/ConfirmDialog.vue";
import SideBar from "./components/SideBar.vue";
// import VueApexCharts from "vue3-apexcharts";
import { ref, onMounted, nextTick } from "vue";
import msalInstance from "@/msalConfig";

export default {
  components: {
    SideBar,
    ConfirmDialog,
    // apexChart: VueApexCharts,
  },
  setup() {
    const isAuthenticated = ref(false);
    const userName = ref("");
    const idToken = ref("");
    // ユーザーID格納用の変数を用意
    const userId = ref("");
    const sidebar = ref("");
    // 最終ログイン時刻格納用の変数を用意
    const last_login_time = ref("");

    // Azure Functions接続先(バックエンド)
    const function_ip = process.env.VUE_APP_API_URL;

    // ログアウトタイマーを管理する変数
    let logoutTimer = null;

    const login = async () => {
      const loginRequest = {
        // 使用するスコープを指定
        scopes: ["user.read"],
        // ここを追加することで、毎回サインイン要求を強制
        // prompt: "login"
      };

      try {
        msalInstance.loginRedirect(loginRequest);
      } catch (err) {
        console.error(err);
        isAuthenticated.value = false;
      }
    };

    const logout = async () => {
      // 前のタイマーが走っていればクリア
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }

      // 現在、ログイン中のアカウント情報を取得
      const accountFilter = {
        homeAccountId: userId.value
      };
      const currentAccount = msalInstance.getAccount(accountFilter);

      // 取得したアカウントで強制ログアウト(どのアカウントをログアウトしますか？表示はさせない)
      await msalInstance.logoutRedirect({account: currentAccount});

      isAuthenticated.value = false;
      // ユーザー名をリセット
      userName.value = "";
      // IDトークンをリセット
      idToken.value = "";
      // ユーザーIDをリセット
      userId.value = "";

      // 全てのセッションstorage情報をクリア
      sessionStorage.clear();
      // Cookieを削除
      // ※有効期限を昔の日付に設定することで削除される
      document.cookie = "user_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      
      // ページをリロードして再認証できるようにする
      window.location.reload();
    };

    const handleAuthentication = async () => {
      try {
        // MSALの初期化を待機
        await msalInstance.initialize();

        const response = await msalInstance.handleRedirectPromise();

        if (response) {
          isAuthenticated.value = true;
          userName.value = response.account.name;
          idToken.value = response.account.idToken;
          // ユーザーIDを取得
          userId.value = response.account.homeAccountId;

          // 次のマイクロタスクで呼び出す
          // ※ユーザーIDが変数に格納されてからでないと、会話履歴を取得できないため
          await nextTick();
          // CSRFトークン、IDトークンの取得・会話履歴の読み込み(「200」OKのみ実施)
          getCsrfToken(userName.value, userId.value);
        } else {
          const accounts = msalInstance.getAllAccounts();
          if (accounts.length > 0) {
            isAuthenticated.value = true;
            userName.value = accounts[0].name;
            idToken.value = accounts[0].idToken;
            // ユーザーIDを取得
            userId.value = accounts[0].homeAccountId;

            // 次のマイクロタスクで呼び出す
            // ※ユーザーIDが変数に格納されてからでないと、会話履歴を取得できないため
            await nextTick();
            // CSRFトークンの取得・会話履歴の読み込み(「200」OKのみ実施)
            getCsrfToken(userName.value, userId.value);
          } else {
            // アカウントが見つからない場合、ログインを開始
            await login();
          }
        }
      } catch (err) {
        console.error(err);
        isAuthenticated.value = false;
      }
    };

    // ログアウトタイマーを開始する
    const startLogoutTimer = () => {
      // 60分 (3600000ms) 後にログアウトを実行
      if (logoutTimer) {
        // 前のタイマーをクリア
        clearTimeout(logoutTimer);
      }
      logoutTimer = setTimeout(() => {
        logout();
        // console.log("無操作状態で60分経過しました");
      }, 3600000);
    };

    // ユーザーのアクションを監視してタイマーをリセット
    const resetLogoutTimer = () => {
      // タイマーを再起動
      startLogoutTimer();
      // console.log("ユーザーのアクションを検知しました\nタイマーを再起動します");
    };

    // ユーザーのアクションを検知
    // マウスを動かす or マウスクリック or キー入力でタイマーをリセット後、タイマーを再起動する
    const initializeTimerReset = () => {
      const events = ["mousemove", "keydown", "click"];
      events.forEach((event) => {
        window.addEventListener(event, resetLogoutTimer);
      });
    };

    // CSRFトークン、IDトークンの取得、前回ログイン日時の抽出(200OKのみ、実行、初回処理)
    const getCsrfToken = async (userName, userId) => {
      // バックエンドの接続先
      const url = function_ip + "&action=GetCSRFToken";

      // ログイン中のユーザーIDをオブジェクトとして定義
      const dataToSend = {
        // ログイン中のユーザー名とIDをセット
        userName: userName,
        userId: userId,
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // IDトークンをヘッダーに含める
            Authorization: `Bearer ${idToken.value}`,
          },
          body: JSON.stringify(dataToSend),
        });

        const data = await response.json();
        // サーバーからのレスポンスに含まれるCSRFトークン
        const csrfToken = data.csrfToken;
        // サーバーからのレスポンスに含まれるユーザートークン
        const user_token = data.userToken;
        // console.log("バックエンド側から取得したユーザーIDごとに生成されたユーザートークン", user_token);

        // サーバーからのレスポンスに含まれる最終ログイン日時
        last_login_time.value = data.last_login_time;

        // セッションストレージにCSRFトークンを保存
        sessionStorage.setItem("csrfToken", csrfToken);

        // Cookieにユーザートークンを保存
        document.cookie = `user_token=${user_token}; path=/; Secure; SameSite=strict`;
        // console.log("Cookieの値をセットしました");

        // ユーザーIDに紐づいた会話履歴の抽出
        sidebar.value.fetchConversations();
      } catch (error) {
        alert("通信エラーが発生しました。接続先を確認してください。");
        console.error("CSRFトークンの取得に失敗しました", error);
      }
    };

    onMounted(() => {
      // 初回、ログイン処理を実行
      handleAuthentication();

      // ログインしたら60分後にログアウトするタイマーを設定
      // 初回起動時、何も操作されていない状態のタイマー開始
      // ※ユーザーが操作するたびに、タイマーがリセット・再起動される
      startLogoutTimer();
      // ユーザーのアクションの検知開始
      initializeTimerReset();
      // console.log("タイマースタート");
    });

    return {
      isAuthenticated,
      userName,
      idToken,
      // ユーザーIDを返す
      userId,
      logout,
      sidebar,
      last_login_time,
    };
  },
  data() {
    return {
      // メッセージ入力フォームで入力された文字列
      newMessage: "",
      // tsuzumiからのレスポンス格納用文字列
      responce: "",
      // メッセージのリスト
      messages: [],
      // 1行に入力可能な文字数
      maxCharsPerLine: 40,
      // メッセージ入力フォーム要素格納用変数
      getTextarea: null,
      // サイドバー表示用フラグ(falseの場合、サイドバーを非表示)
      showSideBar: true,
      // ゴミ箱ボタン表示判定フラグ(message.typeがuser側のみ表示するようにする)
      showTrashButton: {},
      // ぐるぐるGIF表示フラグ(初回はfalseで非表示設定)
      loading: false,
      // 会話セッションIDの格納用変数
      conversation_id: null,
      // チャット履歴確認画面表示判定フラグ
      isDialogVisible: false,
      // 初回データ読み込み判定フラグ
      isInitialize: true,
      // サイドバーボタンで選択中のインデックス(バックアップ用)
      activeindex_backup: 0,
      // バックエンドから受け取ったURL格納用リスト
      imageUrl: [],
      // ApexChartsの設定
      chart: {
        // 各データからグラフ生成時に毎度追加される配列(複数グラフ描画のために必要)
        graphArrayData: [],
      },
      // ApexChartsに描画する各座標
      x_value: "",
      y_value: "",
      // グラフ生成時に使用するパラメーター(初回は-1で初期化)
      graphIndex: -1,
      // グラフデータ、存在チェック
      isGraphData: false,

      // Azure Function接続先IP(バックエンド)
      function_ip: process.env.VUE_APP_API_URL,
    };
  },
  mounted() {
    // textareaの要素を取得(初回のみ)
    this.getTextarea = document.getElementById("textarea");
  },
  methods: {
    callFetchConversations() {
      this.$refs.sidebar.fetchConversations();
    },
    // ユーザー側で入力されたメッセージを画面に出力後、クエリを送信
    sendMessage() {
      // 何も入力されていない場合は、メッセージを送信しない
      if (this.newMessage.trim() === "") {
        return;
      }

      // メッセージを処理するロジック

      // ユーザーのメッセージをリストに追加
      this.messages.push({
        question: { text: this.newMessage, type: "user" },
        answer: null,
        imageUrl: null,
      });

      // ユーザーが入力したメッセージをバックアップ
      const userMessage = this.newMessage;

      // データの変更が反映された後に、コールバックを実施
      this.$nextTick(() => {
        // 最下部まで自動スクロール
        this.scrollMessageContainer();
        // 入力したメッセージをAzure AI Search、LLMへ投げる
        this.replyToMessage(userMessage);
      });
      // 入力内容をクリア
      this.userMessage = "";
      // キーボード入力内容とサイズをリセット
      this.resetTextArea();
    },
    // ユーザー側で入力されたメッセージを受け取り、回答を返す
    async replyToMessage(userMessage) {
      // Azure Funcstionsの接続先を指定
      const api_url = this.function_ip + "&action=PostPromptToAzure";

      // ローディングを開始
      this.loading = true;

      // 一時的に空文字をリストの末尾に追加(ぐるぐる表示をしたいため)
      setTimeout(() => {
        this.messages.push({
          question: null,
          answer: { text: "", type: "bot" },
          imageUrl: null,
        });

        // データの変更が反映された後に、コールバックを実施
        this.$nextTick(() => {
          // 最下部まで自動スクロール
          this.scrollMessageContainer();
        });
      }, 0);

      // 「ユーザーID」と「ユーザーが投げた質問」、「会話セッションID」をjsonにしてPythonに投げる

      // PythonのAPIを呼び出し、プロンプトを投げる

      // 選択中の会話セッションIDと、ユーザーメッセージをオブジェクトとして定義
      const dataToSend = {
        // 選択中の会話セッションIDをセット
        chat_session_id: this.conversation_id,
        // ユーザーが投げた質問をセット
        question_text: userMessage,
      };

      // セッションストレージからCSRFトークンを取得
      const csrfToken = sessionStorage.getItem("csrfToken");
      // ユーザートークンをCookieから取得
      const user_token = this.$cookies.get("user_token");
      // console.log("Cookieの値をゲットしました");

      try {
        // バックエンドにリクエストを送る
        const response = await fetch(api_url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // IDトークンをヘッダに含める
            Authorization: `Bearer ${this.idToken}`,
            // CSRFトークンをヘッダーに含める
            "X-CSRF-TOKEN": csrfToken,
            // ユーザーIDをヘッダーに含める
            "X-USER-ID": this.userId,
            // ユーザーIDごとに振り出されたユーザートークンをヘッダーに含める
            "X-USER-TOKEN": user_token,
          },
          body: JSON.stringify(dataToSend),
        });
        // APIからのレスポンスをjson形式で取得する
        const answer = await response.json();

        // APIから返ってきたjsonデータのうち、resultのみを画面に出力させるための変数に代入
        this.responce = answer.result;
        // 画像URLリストを取得(SASトークン付き)
        this.imageUrl = answer.insite_urls;

        //それぞれの情報をログに出力
        console.log("天気APIからの情報", answer.weather);
        console.log("DBからの情報", answer.db);
        console.log("RAGからの情報", answer.rag);
        console.log("RAGからの画像URL", answer.urls);
        console.log("tsuzumiからの回答", answer.result);

        // 逆質問パターンではない、かつDBデータが返ってきた場合のみ、DBからの情報を受け取る
        // if (answer.db != answer.result && answer.db != null) {
        //   // グラフをセット
        //   this.setGraph();

        //   // グラフ表示用の変数に値をセット(あくまでグラフ描画の準備だけ)
        //   this.prepareChartData(answer.db);
        //   // グラフデータ確認フラグをオン
        //   this.isGraphData = true;
        //   // console.log("グラフを出力");
        // } else {
        //   // グラフデータ確認フラグをオフ
        //   this.isGraphData = false;
        // }
        // ローディング表示を終了、メッセージを画面に出力
        this.addResult();

        // サイドバーが表示されていた場合は、そのまま最新のデータを読み込む
        if (this.showSideBar) {
          // SideBar.vueのfetchConversationsメソッドを呼び出す
          this.$refs.sidebar.fetchConversations();
        }
      } catch (error) {
        //通信エラー時
        console.error(error);

        const errorMessage = "通信エラーが発生しました。接続先を確認してください。";
        // エラーメッセージを代入
        this.responce = errorMessage;

        // グラフデータ確認フラグをオフ
        this.isGraphData = false;

        // ローディング表示を終了、エラーメッセージを画面に出力
        this.addResult();
      }
    },
    // 結果を画面に表示させるためのリストの変数に追加
    addResult() {
      // ローディングを終了
      this.loading = false;

      // リストの末尾に追加した空文字を削除後、再度リストの末尾にサーバーからの応答を追加
      // ※この処理を毎度メッセージが入力されサーバーからの応答が返ってくるたびに実施する
      this.messages.pop();
      // console.log(this.messages);

      // LLMから返ってきた応答 or エラーメッセージを末尾に追加
      setTimeout(() => {
        if (this.isGraphData) {
          this.messages.push({
            question: null,
            answer: { text: this.responce, type: "bot" },
            imageUrl: this.imageUrl,
            // グラフ描画の際のX軸の値をセット(値が返ってきていない場合はnullでセット)
            options: this.chart.graphArrayData[this.graphIndex].options,
            // グラフ描画の際のY軸の値をセット(値が返ってきていない場合はnullでセット)
            series: this.chart.graphArrayData[this.graphIndex].series,
          });
          // console.log("グラフデータあり");
        } else {
          this.messages.push({
            question: null,
            answer: { text: this.responce, type: "bot" },
            imageUrl: this.imageUrl,
            // X軸の値をnullでセット
            options: "",
            // Y軸の値をnullでセット
            series: "",
          });
          // console.log("グラフデータなし");
        }
        // console.log("this.messages", this.messages);

        // 取得したtsuzumiからの回答をクリア
        this.responce = "";
        // 取得した画像URL(ローカル)をクリア
        this.imageUrl = [];

        // データの変更が反映された後に、コールバックを実施
        this.$nextTick(() => {
          // 最下部まで自動スクロール
          this.scrollMessageContainer();
        });
      }, 0);
    },
    // アイコンのパスより、アイコン画像を返す
    getIcon(type) {
      if (type === "user") {
        // ユーザーアイコンのパス
        return require("@/assets/People.png");
      } else if (type === "bot") {
        // ボットアイコンのパス
        return require("@/assets/Robot.png");
      }
    },
    // 返されたテキストの文字列の型チェック
    formatMessage(text) {
      // tsuzumiから返ってきた応答が文字列でない場合は文字列に変換

      let processedText;

      // 文字列がどうか、確認
      if (typeof text === 'string') 
      {
        // 文字列の場合はそのまま
        processedText = text;
        // console.log("文字列が返ってきているため、そのまま渡します");
      } 
      else 
      {
        // それ以外の場合はJSON文字列に変換
        processedText = JSON.stringify(text);
        // console.log("文字列ではないため、JSON文字列に変換します");
        // console.log("JSON変換後の文字列", processedText);
      }

      return processedText;
    },
    // 押下されたキーによって、イベントを制御
    handleKeydown(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        // デフォルトのEnterキー動作を防止（改行の防止）
        event.preventDefault();
        // メッセージを送信
        this.sendMessage();
      } else if (event.key === "Enter" && event.shiftKey) {
        // Shift + Enterの場合は何もしない（改行を挿入)
      }
    },
    // 入力された文字数によって、自動的に改行を行う(1行につき最大40文字、これを超えると自動で改行)
    // textareaの高さを自動調整
    checkTextLength() {
      let text = this.newMessage;
      // 文字列が入力される度に配列に追加、改行された場合に文字列を分割
      let lines = text.split("\n");

      for (let i = 0; i < lines.length; i++) {
        if (lines[i].length > this.maxCharsPerLine) {
          // 行の末尾から改行を挿入する
          let splitLine = lines[i].match(
            new RegExp(".{1," + this.maxCharsPerLine + "}", "g")
          );
          // 現在の行を分割して置き換え
          lines.splice(i, 1, ...splitLine);
        }
      }

      // 改行を挿入して textarea に反映
      this.newMessage = lines.join("\n");

      // textareaの高さを自動調整
      this.resizeTextarea();
    },
    // メッセージ入力フォームで改行される際のCSS高さを自動調整
    resizeTextarea() {
      // 高さをリセットしてから、内容に基づいて高さを再計算
      this.getTextarea.style.height = "auto";
      const newHeight = this.getTextarea.scrollHeight;
      this.getTextarea.style.height = newHeight + "px";
    },
    // メッセージ入力フォームのサイズリセット
    resetTextArea() {
      // 元のサイズにリセット
      if (this.getTextarea.style.height != "43px") {
        this.getTextarea.style.height = "43px";
      }
      // メッセージ内容クリア
      this.newMessage = "";
    },
    // メッセージ出力後の自動スクロール処理
    scrollMessageContainer() {
      // メッセージ出力部分の要素を取得
      const container = this.$refs.messageContainer;
      // 現在の高さを取得して、スクロール位置を最下部に持っていく
      container.scrollTop = container.scrollHeight;
    },
    // サイドバー非表示フラグ制御
    closeSidebar() {
      // サイドバーを閉じる
      this.showSideBar = false;
    },
    // ゴミ箱ボタン表示判定フラグ(マウスオーバー時)
    showButton(zoneId) {
      // ボタン表示判定フラグをオンにして、ゴミ箱ボタンを表示(message.typeがtrueの場合のみ)
      this.showTrashButton[zoneId] = true;
    },
    // ゴミ箱ボタン表示判定フラグ(マウスオーバーしていない時)
    hideButton(zoneId) {
      // ボタン表示判定フラグをオフにして、ゴミ箱ボタンを非表示
      this.showTrashButton[zoneId] = false;
    },
    // フィルタリングされたメッセージをリストに格納、会話セッションIDの取得
    handleSesstionSelect(returnMessages, sesstionid) {
      // SideBar.vueから送られてきたセッションIDごとのデータを受け取る
      const chatMessages = returnMessages;
      // console.log("chatMessages", chatMessages);

      // クリックされたサイドバーボタンの会話セッションIDを取得
      this.conversation_id = sesstionid;
      // console.log("選択された会話セッションID", this.conversation_id);

      // 一度、初回のデータを全て削除
      this.messages.splice(0);
      // console.log("spliceされたデータ", this.messages);

      // 初回のデータをmessagesに代入
      this.messages.push({
        question: null,
        answer: { text: "質問したい事項を入力してください。", type: "bot" },
        imageUrl: null,
      });
      // console.log("1番目に追加された初回データ", this.messages);
      // console.log(chatMessages);

      // 会話履歴データが存在する場合のみ、リストに追加
      if (chatMessages != "") {
        // チャット画面に表示するために、CosmosDBから取得したデータを1つずつmessages(リスト型)に代入
        chatMessages.forEach((msg) => {
          this.messages.push(msg);
        });
        // console.log("2番目以降に追加されたデータ", this.messages);
      }
    },
    // チャット履歴削除画面を表示
    showConfirmDialog() {
      this.isDialogVisible = true;
    },
    // チャット履歴削除画面を非表示
    closeConfirmDialog() {
      this.isDialogVisible = false;
    },
    // 「SideBar.vue」からセッションIDを受け取る
    getSessionId(sessionId) {
      // セッションIDをバックアップ
      this.conversation_id = sessionId;
      // console.log("セッションIDを受け取りました", this.conversation_id);
    },
    // ログイン中のユーザーID、セッションIDとセットで登録されている会話データを全て削除
    deleteSession() {
      // バックエンドの接続先を指定
      const api_url = this.function_ip + "&action=DeleteConversation";

      // セッションストレージからCSRFトークンを取得
      const csrfToken = sessionStorage.getItem("csrfToken");
      // ユーザートークンをCookieから取得
      const user_token = this.$cookies.get("user_token");
      // console.log("Cookieの値をゲットしました");

      fetch(api_url, {
        method: "POST",
        headers: {
          // JSON形式で送信
          "Content-Type": "application/json",
          // IDトークンをヘッダに含める
          Authorization: `Bearer ${this.idToken}`,
          // CSRFトークンをヘッダーに含める
          "X-CSRF-TOKEN": csrfToken,
          // ユーザーIDをヘッダーに含める
          "X-USER-ID": this.userId,
          // ユーザーIDごとに振り出されたユーザートークンをヘッダーに含める
          "X-USER-TOKEN": user_token,
        },
        body: JSON.stringify({
          // セッションIDをstring型に変換してリクエストボディに含める
          sessionId: this.conversation_id,
        }),
      })
        .then((response) => {
          if (response.ok) {
            alert("チャット履歴を削除しました。");

            // サイドバーが表示されている時のみ、最新のデータを読み込み表示を更新
            if (this.showSideBar) {
              // データ削除時には最新のデータを取得するために、再度フラグをtrueにする
              this.isInitialize = true;
              // サイドバーの情報を最新に更新
              // SideBar.vueのfetchConversationsメソッドを呼び出す
              this.$refs.sidebar.fetchConversations();
            }
          } else {
            alert("チャット履歴の削除が失敗しました。");
          }
        })
        .catch((error) => {
          alert("通信エラーが発生しました。接続先を確認してください。");
          console.error("Error:", error);
        });
    },
    // ゴミ箱ボタンで選択された一部の会話データのみを削除
    deleteSomeConversationData(index) {
      // console.log(index);

      // リクエスト時に送るインデックスを作成
      const repair_index = index - 1;
      // console.log("repair_index", repair_index);

      // バックエンドの接続先を指定
      const api_url = this.function_ip + "&action=DeleteSomeConversation";

      // セッションストレージからCSRFトークンを取得
      const csrfToken = sessionStorage.getItem("csrfToken");
      // ユーザートークンをCookieから取得
      const user_token = this.$cookies.get("user_token");
      // console.log("Cookieの値をゲットしました");

      fetch(api_url, {
        method: "POST",
        headers: {
          // JSON形式で送信
          "Content-Type": "application/json",
          // IDトークンをヘッダに含める
          Authorization: `Bearer ${this.idToken}`,
          // CSRFトークンをヘッダーに含める
          "X-CSRF-TOKEN": csrfToken,
          // ユーザーIDをヘッダーに含める
          "X-USER-ID": this.userId,
          // ユーザーIDごとに振り出されたユーザートークンをヘッダーに含める
          "X-USER-TOKEN": user_token,
        },
        body: JSON.stringify({
          // 修正後のインデックスをstring型に変換してリクエストボディに含める
          index: repair_index,
          // セッションIDをstring型に変換してリクエストボディに含める
          sessionId: this.conversation_id,
        }),
      })
        .then((response) => {
          if (response.ok) {
            alert("一部のチャット履歴を削除しました。");

            // サイドバーが表示されている時のみ、最新のデータを読み込み表示を更新
            if (this.showSideBar) {
              this.$refs.sidebar.fetchConversations();
            } else {
              // サイドバーが閉じている時はデータの自動更新ができないため、App.vue側のリストを削除
              this.deleteSomeListData(index);
            }
          } else {
            alert("一部のチャット履歴の削除が失敗しました。");
          }
        })
        .catch((error) => {
          alert("通信エラーが発生しました。接続先を確認してください。");
          console.error("Error:", error);
        });
    },
    // 初回表示フラグをfalseにする
    initializeFalse() {
      this.isInitialize = false;
    },
    // SideBar.vue側のインデックスのバックアップ
    indexBackup(index) {
      this.activeindex_backup = index;
      // console.log("インデックスをバックアップしました");
    },
    // 一部会話履歴の削除時における、ローカルでのリスト削除処理
    deleteSomeListData(index) {
      // 格納されているリストの内容によって、削除するインデックスを分岐

      // リストの中身として、質問と回答がセットで格納されている場合
      // ※つまり、ComosDBからデータを取得後、CosmosDBのリストの形で自動でデータ追加されたケースの場合
      if (this.messages[index].question && this.messages[index].answer) {
        // 対象のリスト1つのみを削除
        this.messages.splice(index, 1);
        // console.log("CosmosDBから取得したデータです");
        // console.log("一部データ削除後のリストデータ一覧", this.messages);
      }
      // questionのみ値が存在する場合
      // ※つまり、サイドバーを閉じた状態で新たにGUIからプロンプトが投げられた場合(question or answerのどちらかがnulになっている状態)
      else {
        // 対象のリスト2つ両方削除
        this.messages.splice(index, 2);
        // console.log("ローカルでリストに追加したデータです");
        // console.log("一部データ削除後のリストデータ一覧", this.messages);
      }
    },
    // グラフ作成用のデータを取得
    prepareChartData(data) {
      // console.log("Azure CosmosDBから受け取ったdata", data);

      // 計測データのグラフ描画判定用
      let measureData = data[0];
      // 計測データ中の苗番号データが存在するか問い合わせ
      let getMeasureData = measureData["date-item_id-seed_id"];
      // console.log("data[0].crop_yeild", data[0].crop_yeild);

      // 収穫量データが返ってきている場合
      if (data[0].crop_yeild != undefined) {
        // データからX軸とY軸の情報を取得
        data.forEach((item) => {
          //アイテムの中身によって、座標に代入する値を制御
          this.dataBranching(item);

          // その他データ描画時
          // X軸をセット
          this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.push(
            this.x_value
          );
          // Y軸をセット
          this.chart.graphArrayData[this.graphIndex].series[0].data.push(
            parseFloat(this.y_value)
          );
        });
        // console.log("収穫量グラフを描画");
      }
      // 総収量データが返ってきている場合
      else if (data[0].weight != undefined) {
        // 各classごとのデータを格納するオブジェクトを初期化
        const groupedData = {};

        data.forEach((item) => {
          //アイテムの中身によって、座標に代入する値を制御
          this.dataBranching(item);

          // groupedDataを更新
          if (!groupedData[item.class]) {
            groupedData[item.class] = { name: item.class, data: [] };
          }

          // X軸とY軸に必要な値を追加
          if (this.x_value && this.y_value !== undefined) {
            // X軸のカテゴリーに日付を追加(重複を避ける)
            if (
              this.x_value &&
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.indexOf(
                this.x_value
              ) === -1
            ) {
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.push(
                this.x_value
              );
            }
            // データを追加
            groupedData[item.class].data.push({
              date: this.x_value,
              value: parseFloat(this.y_value),
            });
          }
        });

        // 各classごとのデータをchart.seriesに変換
        Object.keys(groupedData).forEach((item) => {
          const seriesData = this.chart.graphArrayData[
            this.graphIndex
          ].options.xaxis.categories.map((date) => {
            const dataPoint = groupedData[item].data.find((d) => d.date === date);

            //データがない場合はnullを返す
            return dataPoint ? dataPoint.value : null;
          });
          // console.log("seriesData", seriesData);
          this.chart.graphArrayData[this.graphIndex].series.push({
            name: groupedData[item].name,
            data: seriesData,
          });
        });

        // seriesが空でない場合のみ、そのデータを表示(有効なデータ点を持つ系列だけが表示され、〇表示もデータがある場合のみ表示される)
        // データポイントの中にnullが含まれる場合、その系列を削除
        this.chart.graphArrayData[this.graphIndex].series = this.chart.graphArrayData[
          this.graphIndex
        ].series.filter((series) => series.data.some((value) => value !== null));
        // console.log("総収量グラフを描画");
      }
      // 計測データ情報(growth_data)が返ってきている場合
      else if (getMeasureData != undefined) {
        // 各bed_numberごとのデータを格納するオブジェクトを初期化
        const groupedData = {};
        let index = -1;

        data.forEach((item) => {
          // 配列の中身取得用のインデックスを用意
          index += 1;
          // console.log("index", index);

          //アイテムの中身によって、座標に代入する値を制御
          this.dataBranching(item);

          // インデックスに沿って、配列の要素を取得

          // "date-item_id-seed_id"の値を取得
          const dateItemIdSeed = data[index];
          const getDateItemIdSeed = dateItemIdSeed["date-item_id-seed_id"];
          // console.log("取り出した加工前の苗番号", getDateItemIdSeed);

          // 日付部分を削除して、新しい値を作成

          // '*'の後の文字列を取得
          // ToDo：日付 * 品目ID * 苗番号が最終的な形、それ前提で苗番号を取得するようにする
          const newId = getDateItemIdSeed.split("*")[1].trim().replace(" ", "-");
          // console.log("加工後の苗番号", newId);

          // groupedDataを更新
          if (!groupedData[newId]) {
            groupedData[newId] = { name: newId, data: [] };
          }

          // X軸とY軸に必要な値を追加
          if (this.x_value && this.y_value !== undefined) {
            // X軸のカテゴリーに日付を追加(重複を避ける)
            if (
              this.x_value &&
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.indexOf(
                this.x_value
              ) === -1
            ) {
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.push(
                this.x_value
              );
            }
            // データを追加
            groupedData[newId].data.push({
              date: this.x_value,
              value: parseFloat(this.y_value),
            });
          }
        });

        // 各bed_numberごとのデータをchart.seriesに変換
        Object.keys(groupedData).forEach((bed_number) => {
          const seriesData = this.chart.graphArrayData[
            this.graphIndex
          ].options.xaxis.categories.map((date) => {
            const dataPoint = groupedData[bed_number].data.find((d) => d.date === date);

            //データがない場合はnullを返す
            return dataPoint ? dataPoint.value : null;
          });
          this.chart.graphArrayData[this.graphIndex].series.push({
            name: groupedData[bed_number].name,
            data: seriesData,
          });
        });

        // seriesが空でない場合のみ、そのデータを表示(有効なデータ点を持つ系列だけが表示され、〇表示もデータがある場合のみ表示される)
        // データポイントの中にnullが含まれる場合、その系列を削除
        this.chart.graphArrayData[this.graphIndex].series = this.chart.graphArrayData[
          this.graphIndex
        ].series.filter((series) => series.data.some((value) => value !== null));
        // console.log("計測データのグラフを描画");
      }
      // 養液データが返ってきている場合
      else if (
        data[0].ca != undefined ||
        data[0].k != undefined ||
        data[0].no3 != undefined ||
        data[0].ph != undefined ||
        data[0].ec != undefined
      ) {
        // 各bed_numberごとのデータを格納するオブジェクトを初期化
        const groupedData = {};

        data.forEach((item) => {
          //アイテムの中身によって、座標に代入する値を制御
          this.dataBranching(item);

          // groupedDataを更新
          if (!groupedData[item.bed_number]) {
            groupedData[item.bed_number] = { name: item.bed_number, data: [] };
          }

          // X軸とY軸に必要な値を追加
          if (this.x_value && this.y_value !== undefined) {
            // X軸のカテゴリーに日付を追加(重複を避ける)
            if (
              this.x_value &&
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.indexOf(
                this.x_value
              ) === -1
            ) {
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.push(
                this.x_value
              );
            }

            // データを追加
            groupedData[item.bed_number].data.push({
              date: this.x_value,
              value: parseFloat(this.y_value),
            });
          }
        });

        // 各bed_numberごとのデータをchart.seriesに変換
        Object.keys(groupedData).forEach((bed_number) => {
          const seriesData = this.chart.graphArrayData[
            this.graphIndex
          ].options.xaxis.categories.map((date) => {
            const dataPoint = groupedData[bed_number].data.find((d) => d.date === date);

            //データがない場合はnullを返す
            return dataPoint ? dataPoint.value : null;
          });
          this.chart.graphArrayData[this.graphIndex].series.push({
            name: groupedData[bed_number].name,
            data: seriesData,
          });
        });

        // seriesが空でない場合のみ、そのデータを表示(有効なデータ点を持つ系列だけが表示され、〇表示もデータがある場合のみ表示される)
        // データポイントの中にnullが含まれる場合、その系列を削除
        this.chart.graphArrayData[this.graphIndex].series = this.chart.graphArrayData[
          this.graphIndex
        ].series.filter((series) => series.data.some((value) => value !== null));
        // console.log("養液データのグラフを描画");
      }
      // 糖度データ、ハウス内気温、グリーンハウスモニター情報が返ってきている場合
      else {
        // 各bed_numberごとのデータを格納するオブジェクトを初期化
        const groupedData = {};

        data.forEach((item) => {
          //アイテムの中身によって、座標に代入する値を制御
          this.dataBranching(item);

          // groupedDataを更新
          if (!groupedData[item.bed_number]) {
            groupedData[item.bed_number] = { name: item.bed_number, data: [] };
          }

          // X軸とY軸に必要な値を追加
          if (this.x_value && this.y_value !== undefined) {
            // X軸のカテゴリーに日付を追加(重複を避ける)
            if (
              this.x_value &&
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.indexOf(
                this.x_value
              ) === -1
            ) {
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.push(
                this.x_value
              );
            }

            // データを追加
            groupedData[item.bed_number].data.push({
              date: this.x_value,
              value: parseFloat(this.y_value),
            });
          }
        });

        // 各bed_numberごとのデータをchart.seriesに変換
        Object.keys(groupedData).forEach((bed_number) => {
          const seriesData = this.chart.graphArrayData[
            this.graphIndex
          ].options.xaxis.categories.map((date) => {
            const dataPoint = groupedData[bed_number].data.find((d) => d.date === date);

            //データがない場合はnullを返す
            return dataPoint ? dataPoint.value : null;
          });
          this.chart.graphArrayData[this.graphIndex].series.push({
            name: groupedData[bed_number].name,
            data: seriesData,
          });
        });

        // seriesが空でない場合のみ、そのデータを表示(有効なデータ点を持つ系列だけが表示され、〇表示もデータがある場合のみ表示される)
        // データポイントの中にnullが含まれる場合、その系列を削除
        this.chart.graphArrayData[this.graphIndex].series = this.chart.graphArrayData[
          this.graphIndex
        ].series.filter((series) => series.data.some((value) => value !== null));
        // console.log("糖度データ、ハウス内気温、グリーンハウスモニター情報のグラフを描画");
      }
    },
    // 座標に代入するデータの分岐判断
    dataBranching(item) {
      // 収穫量データ(crop_harvest)が返ってきている場合は、収穫量データを各座標に代入
      if (item.crop_yeild != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に収穫量情報を代入
        this.y_value = item.crop_yeild;
        // console.log("収穫量のグラフを生成します");
      }
      // 糖度データ(brix_state)が返ってきている場合は、糖度データを各座標に代入
      else if (item.brix != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に糖度情報を代入
        this.y_value = item.brix;

        // console.log("糖度のグラフを生成します");
      }
      // ハウス内気温情報(greenhouse_daily_temperature)が返ってきている場合は、それぞれ返ってきたパラメーターの情報を代入
      // 1.「平均気温」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.average_temperature != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「平均気温」情報を代入
        this.y_value = item.average_temperature;

        // console.log("平均気温のグラフを生成します");
      }
      // 2.「昼平均気温」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.average_daytime_temperature != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「昼平均気温」情報を代入
        this.y_value = item.average_daytime_temperature;

        // console.log("昼平均気温のグラフを生成します");
      }
      // 3.「夜平均気温」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.average_nighttime_temperature != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「夜平均気温」情報を代入
        this.y_value = item.average_nighttime_temperature;

        // console.log("夜平均気温のグラフを生成します");
      }
      // 4.「最高気温」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.max_temperature != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「最高気温」情報を代入
        this.y_value = item.max_temperature;

        // console.log("最高気温のグラフを生成します");
      }
      // 5.「最低気温」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.min_temperature != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「最低気温」情報を代入
        this.y_value = item.min_temperature;

        // console.log("最低気温のグラフを生成します");
      }
      // グリーンハウスモニター情報(greenhouse_info)が返ってきている場合は、それぞれ返ってきたパラメーターの情報を代入
      // 1.「ハウス内温度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.inside_temperature != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「ハウス内温度」情報を代入
        this.y_value = item.inside_temperature;

        // console.log("ハウス内温度のグラフを生成します");
      }
      // 2.「室外温度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.outside_temperature != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「室外温度」情報を代入
        this.y_value = item.outside_temperature;

        // console.log("室外温度のグラフを生成します");
      }
      // 3.「目標温度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.target_temperature != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「目標温度」情報を代入
        this.y_value = item.target_temperature;

        // console.log("目標温度のグラフを生成します");
      }
      // 4.「ハウス内相対湿度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.inside_relative_humidity != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「ハウス内相対湿度」情報を代入
        this.y_value = item.inside_relative_humidity;

        // console.log("ハウス内相対湿度のグラフを生成します");
      }
      // 5.「室外相対湿度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.outside_relative_humidity != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「室外相対湿度」情報を代入
        this.y_value = item.outside_relative_humidity;

        // console.log("室外相対湿度のグラフを生成します");
      }
      // 6.「屋外日射」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.solar_radiation_intensity != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「屋外日射」情報を代入
        this.y_value = item.solar_radiation_intensity;

        // console.log("屋外日射のグラフを生成します");
      }
      // 7.「CO2濃度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.co2_concentration != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「CO2濃度」情報を代入
        this.y_value = item.co2_concentration;

        // console.log("CO2濃度のグラフを生成します");
      }
      // 8.「光合成速度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.photosynthesis_rate != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「光合成速度」情報を代入
        this.y_value = item.photosynthesis_rate;

        // console.log("光合成速度のグラフを生成します");
      }
      // 総収量情報(gross_harvest)が返ってきている場合は、返ってきたパラメーターの情報を代入
      else if (item.weight != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「重量」情報を代入
        this.y_value = item.weight;

        // console.log("重量のグラフを生成します");
      }
      // 計測データ情報(growth_data)が返ってきている場合は、返ってきたパラメーターの情報を代入
      // 1. 「SPAD値」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.quantity_4 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「SPAD値」情報を代入
        this.y_value = item.quantity_4;

        // console.log("SPAD値のグラフを生成します");
      }
      // 2. 「茎長(length_1)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.length_1 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「茎長」情報を代入
        this.y_value = item.length_1;

        // console.log("茎長のグラフを生成します");
      }
      // 3. 「葉長(length_2)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.length_2 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「葉長」情報を代入
        this.y_value = item.length_2;

        // console.log("葉長のグラフを生成します");
      }
      // 4. 「葉幅(length_3)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.length_3 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「葉幅」情報を代入
        this.y_value = item.length_3;

        // console.log("葉幅のグラフを生成します");
      }
      // 5. 「茎径(diameter)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.diameter != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「茎径」情報を代入
        this.y_value = item.diameter;

        // console.log("茎径のグラフを生成します");
      }
      // 6. 「開花段数(quantity_1)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.quantity_1 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「開花段数」情報を代入
        this.y_value = item.quantity_1;

        // console.log("開花段数のグラフを生成します");
      }
      // 7. 「全葉数(quantity_2)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.quantity_2 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「全葉数」情報を代入
        this.y_value = item.quantity_2;

        // console.log("全葉数のグラフを生成します");
      }
      // 8. 「収穫段数(quantity_3)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.quantity_3 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「収穫段数」情報を代入
        this.y_value = item.quantity_3;

        // console.log("収穫段数のグラフを生成します");
      }
      // 養液情報(nutrient_solution)が返ってきている場合は、返ってきたパラメーターの情報を代入
      // 1. 「カルシウム(ca)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.ca != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「カルシウム」情報を代入
        this.y_value = item.ca;

        // console.log("カルシウムのグラフを生成します");
      }
      // 2. 「カリウム(k)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.k != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「カリウム」情報を代入
        this.y_value = item.k;

        // console.log("カリウムのグラフを生成します");
      }
      // 3. 「窒素(no3)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.no3 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「窒素」情報を代入
        this.y_value = item.no3;

        // console.log("窒素のグラフを生成します");
      }
      // 4. 「ph(pH)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.ph != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「pH」情報を代入
        this.y_value = item.ph;

        // console.log("pHのグラフを生成します");
      }
      // 5. 「ec(EC値)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.ec != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「EC値」情報を代入
        this.y_value = item.ec;

        // console.log("EC値のグラフを生成します");
      }
    },
    // ApexChartsで描画させるグラフの配列を用意
    setGraph() {
      const graphArray = {
        options: {
          chart: {
            // ツールバーの設定
            toolbar: {
              // 非表示にする
              show: false,
            },
          },
          // グラフのX軸設定
          xaxis: {
            // X軸の値を格納
            categories: [],
          },
          // グラフのマーカー設定
          markers: {
            // マーカーのサイズを指定(0の場合、非表示になる)
            size: 1,
          },
          // ツールチップ設定
          tooltip: {
            // ツールチップを有効
            enabled: true,
            // ツールチップのデザインを黒基調に設定
            theme: "dark",
          },
        },
        // グラフのY軸設定
        series: [
          {
            // Y軸データの名称を指定(この部分は現在、空白にしている)
            name: "",
            // Y軸の値
            data: [],
          },
        ],
      };

      // 新しいグラフ生成用のグラフオブジェクトを配列に追加
      // 1つのグラフとして描画可能
      this.chart.graphArrayData.push(graphArray);
      // console.log("新しいグラフセット", this.chart.graphArrayData);

      // グラフのインデックスを1増やす
      this.graphIndex += 1;
      // console.log("graphIndex", this.graphIndex);
    },
  },
};
</script>

<style>
/*画面全体*/
html,
body {
  padding: 0;
  margin: 0;
  background-color: rgb(136, 174, 82);
}

/*ChatBotのタイトル*/
.title {
  color: white;
  text-align: center;
}

/*サインアウトボタン*/
.signoutbtn {
  /*絶対位置指定*/
  position: absolute;
  /*右端に配置*/
  right: 10px;
  /*上端に配置*/
  top: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/*三本線のアイコンボタン*/
.threebaricon {
  position: absolute;
  left: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/*三本線アイコン*/
.threebar-icon {
  height: 20px;
  width: 20px;
}

/*メッセージ入力フォーム全体設定*/
.generalformposition {
  /*positionをabsoluteにする*/
  position: absolute;
  /*画面下に固定*/
  top: 90%;
  /*真ん中に固定*/
  /* 左右中央に */
  left: 50%;
  /* 幅の50%分左に移動して中央配置 */
  transform: translateX(-50%);
  overflow-x: hidden;
}

/*メッセージ入力用フィールド*/
.input-container {
  /*要素を横並びに設定*/
  display: flex;
  position: relative;
  /* 上部の余白を追加 */
  margin-top: 10px;
}

/*メッセージ入力フォーム*/
textarea {
  width: 700px;
  border-radius: 10px;
  font-size: 16px;
  /*入力フォームの枠線を設定*/
  border: 1px solid black;
  /*改行されたときの入力フォームの最大・最小値を指定*/
  min-height: 42px;
  max-height: 200px;
  /*ユーザーによるリサイズを制限*/
  resize: none;
  overflow-y: auto;
  padding-left: 15px;
}

/*入力文字列の位置を設定*/
textarea {
  /*placeholderと入力開始位置を設定*/
  padding-left: 15px;
  padding-bottom: 0;
  /*改行した時の文字列の間隔を設定*/
  line-height: 1.3;
}

/*メッセージ送信ボタン(飛行機アイコン仕様)*/
.sendbtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  /*初回、画面下の中心にあるメッセージ入力フォームを基準にして相対positionを設定*/
  position: relative;
  /*メッセージ入力フォームの右端から50pxの部分に飛行機ボタンを配置*/
  right: 50px;
}

/*紙飛行機アイコン*/
.paperfly-icon {
  height: 30px;
  width: 30px;
}

/*メッセージ表示部分(可変)全体*/
.message-container {
  /*子要素をフレックスボックスとして指定*/
  display: flex;
  /* 縦に積み重ねる */
  flex-direction: column;
  /* コンテナの高さを制限 */
  max-height: 80vh;
  /* 縦方向のスクロールを許可 */
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

/*メッセージ繰り返し出力部分*/
.message {
  /* メッセージをブロックレベルに */
  display: block;
  width: 100%;
  margin-bottom: 10px;
  /*左揃えに設定*/
  text-align: left;
}

/*アイコン表示設定(親要素)*/
.messageicon-container {
  /*相対位置を指定*/
  position: relative;
}

/*アイコン表示設定*/
.message-icon {
  /*縦横の幅を指定*/
  height: 30px;
  width: 30px;
  /* 絶対位置を指定 */
  position: absolute;
  /* 23px下に移動 */
  padding-top: 23px;
  /*左端からの幅を指定*/
  padding-left: 27%;
}

/*ゴミ箱ボタン表示設定(ユーザー側のみ)*/
.trashicon {
  /* 絶対位置を指定 */
  position: absolute;
  /* 23px下に移動 */
  padding-top: 30px;
  /*右端からの幅を指定*/
  right: 3%;
  /*ボタンを透明に設定*/
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/*ゴミ箱アイコン表示設定(ユーザー側のみ)*/
.trash-icon {
  /*縦横の幅を指定*/
  height: 20px;
  width: 20px;
}

/*メッセージ出力部分*/
.message-text {
  /* display: block; */
  width: 100%;
  /*ブロック内における上下左右の余白を設定*/
  padding: 30px;
  /*文字の折り返しを設定*/
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  /*フォント色を指定*/
  color: white;
  /*フォントサイズを指定*/
  font-size: 17.5px;
  /*左端からの余白部分を設定*/
  padding-left: 31%;
  /*右端からの余白部分を設定*/
  padding-right: 69%;
  /*最大幅を半角65文字分に設定(これを超えると、文字列が折り返される)*/
  max-width: 65ch;
  /* 改行を保持 */
  white-space: pre-wrap;
}

/*ユーザメッセージ表示部分(botの表示部分のみ、背景色を変更)*/
.user .message-text {
  /* ユーザー側メッセージの背景色 */
  background-color: rgb(136, 174, 82);
}

/*botメッセージ表示部分(botの表示部分のみ、背景色を変更)*/
.bot .message-text {
  /* bot側メッセージの背景色 */
  background-color: rgb(160, 185, 126);
}

/*botモードかつ、ぐるぐる表示部分(botの表示部分のみ、背景色を変更、かつぐるぐるも表示)*/
.bot .loader {
  /* bot側メッセージの背景色 */
  background-color: rgb(160, 185, 126);
}

/*ぐるぐる表示部分*/
.loader {
  /*色付け部分を画面全体に伸ばす*/
  width: 100%;
  /*ブロック内における上下左右の余白を設定*/
  padding: 30px;
  /*左端からの余白部分を設定*/
  padding-left: 31%;
  /*右端からの余白部分を設定*/
  padding-right: 69%;
}

/*AI Searchから取得した画像の大きさ設定*/
.image-size {
  /*縦を50%に縮小*/
  height: 50%;
  /*横を50%に縮小*/
  width: 50%;
}

/*デザインA(スマートフォン)*/
/*スマートフォンサイズ、599px以下の時に適用するCSSを設定*/
/*変化させたいclassに変更を加えており、その他定義していないclassについてはデフォルトのCSSを適用している*/
@media screen and (max-width: 599px) {
  .chatbot-container {
    /* 1カラムに変更 */
    grid-template-columns: 1fr;
    /* 行を自動調整 */
    grid-template-rows: auto auto auto;
  }

  /*スマホサイズの場合には、アイコン、テキストのフォントを左側に寄せてサイズを小さくする*/

  /*サイドバーボタンのアイコン*/
  .threebar-icon {
    width: 15px;
    height: 15px;
  }

  /*チャット画面中のタイトル*/
  .title {
    font-size: 16px;
    text-align: center;
  }

  /*サインアウトボタン*/
  .signoutbtn {
    position: absolute;
    top: 10px;
    right: 5px;
    font-size: 10px;
  }

  /*メッセージアイコン*/
  .message-icon {
    /*縦横の幅を指定*/
    height: 25px;
    width: 25px;
    /* 絶対位置を指定 */
    position: absolute;
    /* 23px下に移動 */
    padding-top: 23px;
    /*左端からの幅を指定*/
    padding-left: 3%;
  }

  /*メッセージ表示領域*/
  .message-text {
    width: 100%;
    /*ブロック内における上下左右の余白を設定*/
    padding: 30px;
    /*文字の折り返しを設定*/
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    /*フォント色を指定*/
    color: white;
    /*フォントサイズを指定*/
    font-size: 15px;
    /*左端からの余白部分を設定*/
    padding-left: 15%;
    /*右端からの余白部分を設定*/
    padding-right: 69%;
    /*最大幅を半角30文字分に設定(これを超えると、文字列が折り返される)*/
    max-width: 30ch;
    /* 改行を保持 */
    white-space: pre-wrap;
  }

  /*メッセージ入力フォーム(全体)*/
  .generalformposition {
    /*100%にすると下の幅が広がってしまい、無駄なスクロールが増えるため90%に設定*/
    width: 90%;
  }

  /*メッセージ入力フォーム(子要素(送信ボタン含める))*/
  .input-container {
    position: relative;
    /*左側から右側に20px寄せる*/
    left: 20px;
  }

  /*LLMからの回答待ち、ぐるぐる表示*/
  .loader {
    /*左端からの余白部分を設定*/
    padding-left: 15%;
    /*右端からの余白部分を設定*/
    padding-right: 69%;
  }

  /*AI Searchから取得した画像の大きさ設定*/
  .image-size {
    /*縦を100%に拡大*/
    height: 100%;
    /*横を100%に拡大*/
    width: 100%;
  }
}

/*デザインB(タブレット縦)*/
/*タブレット(縦)サイズ、600px以上、1079px以下の時に適用するCSSを設定*/
/*変化させたいclassに変更を加えており、その他定義していないclassについてはデフォルトのCSSを適用している*/
@media screen and (min-width: 600px) and (max-width: 1079px) {

  /*タブレット(縦)サイズの場合には、アイコン、テキストのフォントを左側に寄せるのみ*/

  /*メッセージアイコン*/
  .message-icon {
    /* 絶対位置を指定 */
    position: absolute;
    /* 23px下に移動 */
    padding-top: 23px;
    /*左端からの幅を指定*/
    padding-left: 5%;
  }

  /*メッセージ表示領域*/
  .message-text {
    /*左端からの余白部分を設定*/
    padding-left: 13%;
    /*右端からの余白部分を設定*/
    padding-right: 69%;
    /*最大幅を半角50文字分に設定(これを超えると、文字列が折り返される)*/
    max-width: 50ch;
  }

  /*メッセージ入力フォーム(全体)*/
  .generalformposition {
    /*100%にすると無駄なスクロールが増えるため90%に設定*/
    width: 90%;
  }

  /*LLMからの回答待ち、ぐるぐる表示*/
  .loader {
    /*左端からの余白部分を設定*/
    padding-left: 13%;
    /*右端からの余白部分を設定*/
    padding-right: 69%;
  }

  /*AI Searchから取得した画像の大きさ設定*/
  .image-size {
    /*縦を100%に拡大*/
    height: 100%;
    /*横を100%に拡大*/
    width: 100%;
  }  
}

/*デザインC(PC/タブレット横)*/
/*1080px以上になった場合には、デフォルトで定義したCSS通りに描画*/

/*高さが基準のピクセルよりも下回った場合には、メッセージ入力フォームの位置を上にずらす*/

/*高さが600pxを下回った場合*/
@media screen and (max-height: 600px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 88%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を減らす */
    margin-top: 5px;
  }
}

/*高さが420pxを下回った場合*/
@media screen and (max-height: 420px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 86%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を減らす */
    margin-top: 3px;
  }
}

/*高さが350pxを下回った場合*/
@media screen and (max-height: 350px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 84%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を減らす */
    margin-top: 1px;
  }
}

/*高さが300pxを下回った場合*/
@media screen and (max-height: 300px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 82%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが255pxを下回った場合*/
@media screen and (max-height: 255px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 80%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが250pxを下回った場合*/
@media screen and (max-height: 250px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 78%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが220pxを下回った場合*/
@media screen and (max-height: 220px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 76%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが200pxを下回った場合*/
@media screen and (max-height: 200px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 74%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが180pxを下回った場合*/
@media screen and (max-height: 180px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 72%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが170pxを下回った場合*/
@media screen and (max-height: 170px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 70%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが155pxを下回った場合*/
@media screen and (max-height: 155px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 68%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが145pxを下回った場合*/
@media screen and (max-height: 145px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 66%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが135pxを下回った場合*/
@media screen and (max-height: 135px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 64%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが130pxを下回った場合*/
@media screen and (max-height: 130px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 62%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが120pxを下回った場合*/
@media screen and (max-height: 120px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 60%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが115pxを下回った場合*/
@media screen and (max-height: 115px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 58%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが110pxを下回った場合*/
@media screen and (max-height: 110px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 56%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

</style>
